// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lt-apie-mus-js": () => import("./../../../src/pages/lt/apie-mus.js" /* webpackChunkName: "component---src-pages-lt-apie-mus-js" */),
  "component---src-pages-lt-index-js": () => import("./../../../src/pages/lt/index.js" /* webpackChunkName: "component---src-pages-lt-index-js" */),
  "component---src-pages-lt-kontaktai-js": () => import("./../../../src/pages/lt/kontaktai.js" /* webpackChunkName: "component---src-pages-lt-kontaktai-js" */),
  "component---src-pages-lt-naftos-ir-duju-gavyba-js": () => import("./../../../src/pages/lt/naftos-ir-dujų-gavyba.js" /* webpackChunkName: "component---src-pages-lt-naftos-ir-duju-gavyba-js" */),
  "component---src-pages-lt-purolite-js": () => import("./../../../src/pages/lt/purolite.js" /* webpackChunkName: "component---src-pages-lt-purolite-js" */),
  "component---src-pages-lt-reagentai-cukraus-pramonei-js": () => import("./../../../src/pages/lt/reagentai-cukraus-pramonei.js" /* webpackChunkName: "component---src-pages-lt-reagentai-cukraus-pramonei-js" */),
  "component---src-pages-lt-reagentai-popieriaus-pramonei-js": () => import("./../../../src/pages/lt/reagentai-popieriaus-pramonei.js" /* webpackChunkName: "component---src-pages-lt-reagentai-popieriaus-pramonei-js" */),
  "component---src-pages-lt-vandens-apdirbimas-js": () => import("./../../../src/pages/lt/vandens-apdirbimas.js" /* webpackChunkName: "component---src-pages-lt-vandens-apdirbimas-js" */),
  "component---src-pages-oil-field-chemicals-js": () => import("./../../../src/pages/oil-field-chemicals.js" /* webpackChunkName: "component---src-pages-oil-field-chemicals-js" */),
  "component---src-pages-paper-processing-chemicals-js": () => import("./../../../src/pages/paper-processing-chemicals.js" /* webpackChunkName: "component---src-pages-paper-processing-chemicals-js" */),
  "component---src-pages-purolite-js": () => import("./../../../src/pages/purolite.js" /* webpackChunkName: "component---src-pages-purolite-js" */),
  "component---src-pages-ru-index-js": () => import("./../../../src/pages/ru/index.js" /* webpackChunkName: "component---src-pages-ru-index-js" */),
  "component---src-pages-ru-purolite-js": () => import("./../../../src/pages/ru/purolite.js" /* webpackChunkName: "component---src-pages-ru-purolite-js" */),
  "component---src-pages-ru-водоподготовка-js": () => import("./../../../src/pages/ru/водоподготовка.js" /* webpackChunkName: "component---src-pages-ru-водоподготовка-js" */),
  "component---src-pages-ru-контакты-js": () => import("./../../../src/pages/ru/контакты.js" /* webpackChunkName: "component---src-pages-ru-контакты-js" */),
  "component---src-pages-ru-о-нас-js": () => import("./../../../src/pages/ru/о-нас.js" /* webpackChunkName: "component---src-pages-ru-о-нас-js" */),
  "component---src-pages-ru-реагенты-бумажной-промышленности-js": () => import("./../../../src/pages/ru/реагенты-бумажной-промышленности.js" /* webpackChunkName: "component---src-pages-ru-реагенты-бумажной-промышленности-js" */),
  "component---src-pages-ru-реагенты-газо-и-нефтепромысла-js": () => import("./../../../src/pages/ru/реагенты-газо-и-нефтепромысла.js" /* webpackChunkName: "component---src-pages-ru-реагенты-газо-и-нефтепромысла-js" */),
  "component---src-pages-ru-реагенты-сахарного-производства-js": () => import("./../../../src/pages/ru/реагенты-сахарного-производства.js" /* webpackChunkName: "component---src-pages-ru-реагенты-сахарного-производства-js" */),
  "component---src-pages-sugar-processing-chemicals-js": () => import("./../../../src/pages/sugar-processing-chemicals.js" /* webpackChunkName: "component---src-pages-sugar-processing-chemicals-js" */),
  "component---src-pages-water-treatment-js": () => import("./../../../src/pages/water-treatment.js" /* webpackChunkName: "component---src-pages-water-treatment-js" */)
}

